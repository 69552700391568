
import { Component, Vue } from "vue-property-decorator";
import { Foundation } from "@/utils/Foundation";
import API_MerchantList from "@/utils/API/merchant/MerchantList";
import API_FundRecord from "@/utils/API/finance/FundRecord";
import { _financedepartment } from "@/utils/pageConfig/videovips/_financedepartment";
import recharge from "./recharge.vue";
import {
    capitalFlow,
    sourceChannelText,
} from "@/utils/pageConfig/videovips/common";
import Api_videoApi from "@/utils/API/videovip/videoApi";

@Component({
    components: {
        recharge,
    },
})
export default class RechargeRecord extends Vue {
    pageConfig: any = _financedepartment;
    tableData: any = [];
    showTable: boolean = false;
    total: number = 0;
    params: any = {
        current: 1,
        size: 20,
        scenes: capitalFlow.RECHARGE,
    };
    exportForm: any = {
        type: {
            tag: "RESOURCE_PLATFORM_FUND_RECORD",
            url: "core/api/v1/admin/c/export/exec/resource/platform/fund/record/admin",
        },
        fileName: "充值记录",
    };

    async mounted() {
        // 搜索框商户列表
        let options: any = [];
        let res: any = await Api_videoApi.getOpenresource();
        res.map((e: any) => {
            options.push({ label: e.coName, value: e.id });
        });

        this.pageConfig.search[0].options = options;
    }

    /*获取表格数据*/
    async getTableData() {
        let res: any = await Api_videoApi.fundrecord(this.params);
        this.tableData =
            (res.records &&
                res.records.length > 0 &&
                res.records.map((e: any) => {
                    e._sceneEm = Foundation.findInArray(
                        sourceChannelText,
                        "value",
                        e.sceneEm
                    ).label;
                    e._amount = Foundation.toYUAN(e.amount);
                    e._currentBalance = Foundation.toYUAN(e.currentBalance);
                    e._recordTime = Foundation.dateFormat(e.recordTime);
                    e._confirmFlag = e.confirmFlag ? "是" : "否";
                    e._confirmAmount = Foundation.toYUAN(e.confirmAmount);
                    return e;
                })) ||
            [];
        this.total = res.total;
    }

    /*底部分页切换*/
    currentChange(pageNumber: number) {
        this.params.current = pageNumber;
        this.getTableData();
    }

    /*底部分页条数切换*/
    sizeChange(sizeNumber: number) {
        this.params.size = sizeNumber;
        this.getTableData();
    }

    /*查询*/
    handleSearch() {
        this.showTable = true;
        let query: any = Foundation.resetParams(
            (this.$refs.searchForm as any).form,
            true
        );
        query = Foundation.resetDate(query);
        this.exportForm.condition = {
            scenes: capitalFlow.RECHARGE,
            ...query,
        };
        this.params = {
            current: 1,
            size: 20,
            scenes: capitalFlow.RECHARGE,
            ...query,
        };
        this.getTableData();
    }

    summaryMethod(param: any) {
        const { data } = param;
        let sums: any = ["合计：", "", "**", "", "", "", "", "", "**"];
        const amountTotal = data.map((item: any) => Number(item.amount));
        const confirmAmountTotal = data.map((item: any) =>
            Number(item.confirmAmount)
        );
        sums[2] = Foundation.toYUAN(
            amountTotal.reduce((prev: any, curr: any) => {
                return prev + curr;
            }, 0)
        );
        sums[8] = confirmAmountTotal.reduce((prev: any, curr: any) => {
            return prev + curr;
        }, 0);
        return sums;
    }

    /*编辑*/
    handleEdit(data: any) {
        (this.$refs.recharge as any).show(data);
    }

    /*导出*/
    handleExport() {
        (this.$refs.exports as any).show(this.exportForm);
    }
}
