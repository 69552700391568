export const capitalFlow = {
    // 充值流水
    RECHARGE: 'RECHARGE',
    // 提现/退款
    CASH_OUT: 'CASH_OUT',
    // 资源消费
    RESOURCE: 'RESOURCE',
    //  垫付冲账
    ADVANCE_CLEAR: 'ADVANCE_CLEAR',
}
/**
 * 资金流水类型数组
 */
export const sourceChannelText = [
    {
        label: '充值',
        value: capitalFlow.RECHARGE,
    },
    {
        label: '退款',
        value: capitalFlow.CASH_OUT,
    },
    {
        label: '资源消费',
        value: capitalFlow.RESOURCE,
    },
    {
        label: '垫付冲账',
        value: capitalFlow.ADVANCE_CLEAR,
    }
];
//操作类型
export const scenario = {
    // 增加余额
    INC: 'INC',
    // 扣除余额
    DEC: 'DEC',
    // 冻结余额
    FREEZE: 'FREEZE',
    //   解冻金额
    THAWED: 'THAWED',
    //   增加退款金额
    REFUND: 'REFUND',
    //   垫付，增加垫付金额
    ADVANCE: 'ADVANCE',
    //    垫付扣除，减少垫付金额（客户打钱填补垫付金额）
    DEC_ADVANCE: 'DEC_ADVANCE',
}
/**
 * 资金流水类型数组
 */
export const scenarioText = [
    {
        label: '增加余额',
        value: scenario.INC,
    },
    {
        label: '扣除余额',
        value: scenario.DEC,
    },
    {
        label: '冻结余额',
        value: scenario.FREEZE,
    },
    {
        label: '解冻金额',
        value: scenario.THAWED,
    },
    {
        label: '增加退款金额',
        value: scenario.REFUND,
    },
    {
        label: '垫付',
        value: scenario.ADVANCE,
    },
    {
        label: '垫付扣除',
        value: scenario.DEC_ADVANCE,
    }
];
//操作场景
export const operation = {
    // 增加余额
    INC: 'INC',
    // 扣除余额
    DEC: 'DEC',
    // 冻结余额
    FREEZE: 'FREEZE',
    //   解冻金额
    THAWED: 'THAWED',
    //   增加退款金额
    REFUND: 'REFUND',
    //   垫付，增加垫付金额
    ADVANCE: 'ADVANCE',
    //    垫付扣除，减少垫付金额（客户打钱填补垫付金额）
    DEC_ADVANCE: 'DEC_ADVANCE',
}