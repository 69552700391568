
import { Component, Vue } from "vue-property-decorator";
import rechargeRecord from "./components/rechargeRecord.vue";
import refundRecord from "./components/refundRecord.vue";

@Component({
    components: {
        rechargeRecord,
        refundRecord
    },
})
export default class Index extends Vue {
    tabName: string = "recharge";
}
